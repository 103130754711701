export function useUserAuth() {
  const uiStore = useUiStore();
  const {
    openConnectModal,
  } = uiStore;

  function handleOpenRegisterModal() {
    // Present Register modal
    openConnectModal({
      message: 'Experience winning in Web3 by connecting your wallet',
    });
  }

  return {
    handleOpenRegisterModal,
  };
}
